import React from 'react';
import PropTypes from 'prop-types';
import { SectionContainer, Line, SideTab, SectionTitle, SideBlocker, Tags } from './section.css';
import { Link } from 'gatsby'


const Section = ({ children, type, as = '', size, tags, bg, section }) => {
  console.log("tags in section: ", tags)
  console.log(type)
  return (
    <>
      {/* check if there are tags */}
      {tags !== null && tags !== undefined ?

        <Tags>
          {/* tag one */}
          {tags[0] !== null && tags[0] !== undefined ?
            <Link to={type === "article" ? `/written/tags/${tags[0].toLowerCase()}` :
              type === "podcast" ? `/audio/tags/${tags[0].toLowerCase()}` :
                type === "video" ? `/video/tags/${tags[0].toLowerCase()}` :
                  ""
            }>
              <SideTab tag={tags !== undefined && tags !== null ? tags[0] : null} />
            </Link>
            : <div style={{ display: "none" }} />}


          {/* tag two */}
          {tags[1] !== null && tags[1] !== undefined ?
            <Link to={type === "article" ? `/written/tags/${tags[1].toLowerCase()}` :
              type === "podcast" ? `/audio/tags/${tags[1].toLowerCase()}` :
                type === "video" ? `/video/tags/${tags[1].toLowerCase()}` :
                  ""
            }>
              <SideTab tag={tags !== undefined && tags !== null ? tags[1] : null} />
            </Link>
            : <div style={{ display: "none" }} />}


          {/* tag three */}
          {tags[2] !== null && tags[2] !== undefined ?
            <Link to={type === "article" ? `/written/tags/${tags[2].toLowerCase()}` :
              type === "podcast" ? `/audio/tags/${tags[2].toLowerCase()}` :
                type === "video" ? `/video/tags/${tags[2].toLowerCase()}` :
                  ""}>
              <SideTab tag={tags !== undefined && tags !== null ? tags[2] : null} />
            </Link>
            : <div style={{ display: "none" }} />}

        </Tags>
        : <div style={{ display: "none" }} />}

      <SectionContainer section={section} type={type} bg={bg} size={size} >
        <SectionTitle bg={bg}>{section}</SectionTitle>
        {/* <SideBlocker type={type} /> */}
        <Line type={type} section={section} />
        {children}
      </SectionContainer>
    </>
  );
};

Section.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(['large']),
};

export default Section;