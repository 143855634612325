import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Img from 'gatsby-image'
import { blue, orange, grey, } from "../../constants/theme"


export const HomeStatementStyle = styled.h1`
  font-size: 5rem;
  font-family: 'Termina';
  padding-top: 8.5rem;
  padding-bottom: 5rem;
  line-height: 5rem;
  padding-left: .1em;
  position: relative;
  font-weight: 700;
  text-transform: uppercase;
  color: grey; 

  ${MEDIA.L_TABLET`
  margin-bottom: -12.5rem;

  `};

  ${MEDIA.TABLET`
  font-size: 4rem;
  margin-bottom: -14rem;

  line-height: 4.5rem;
  padding-top: 7.5rem;
  // top: 7rem;
  `};

  ${MEDIA.PHONE`
  padding-top: 7.5rem;
  margin-bottom: -12.5rem;

     font-size: 2.75rem;
     width: 80%;
     line-height: 3rem;

  `};
  ${MEDIA.M_PHONE`
font-size: 2.1rem;
line-height: 2.5rem;
padding-bottom: 1rem;
margin-left: .2rem;
width: 100%;

  `};

  ${MEDIA.S_PHONE`
font-size: 1.8rem;
font-weight: 700;
line-height: 2rem;
padding-bottom: 2rem;
  `};
`;

export const WordLoop = styled.div`
writing-mode: horizontal-tb;
text-orientation: sideways;
width: 100%;
position: static;
left: 0rem;
top: 5rem;
color: ${blue};
width: auto;
// background: blue;
text-transform: uppercase;
font-weight: 700;

@media (max-width: 1050px) {
  // top: 35rem;
}
@media (max-width: 550px) {
  left: .5rem;
}
`


export const FeatureStatementStyle = styled.div`
  font-size: ${props => (props.type === "category" ? `8rem` : "9rem")};
  font-family: 'Termina';
  z-index: 4;
  height: 100%;
  width: 100%;
  color: ${props => (props.color === "1" ? `black` : "white")};
  position: absolute;
  margin-top: -17.5rem;
  display: flex;
  align-items: center;
  top: ${props => (props.type === "category" ? `17.5rem` : "7rem")};
  padding-left: .1em;
  background: ${props => (props.bgImg ? `url(${props.bgImg})` : "none")};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  // background: red;
  text-shadow: 1px 4px 6px rgba(0,0,0,0.33);
  font-weight: 700;
  text-transform: uppercase;

transition: .5s ease all;
  &:hover {
    transition: .5s ease all;

    > * {
      transition: .5s ease all;

      text-shadow: 3px 6px 10px ${blue};
      cursor: pointer;
    }
  }

  ${MEDIA.L_TABLET`

  `};

  ${MEDIA.TABLET`
  font-size: 9rem;
  // top: 7rem;
  `};

  ${MEDIA.PHONE`
  top: 5rem;
     font-size: 4.5rem;
     line-height: 7rem;
     padding-top: 45%;
  `};

  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`
font-size: 3.5rem;
font-weight: 800;
  `};
`;

export const FeatureTitle = styled.h1`
// position: absolute;
margin-left: 1rem;
color: ${grey};
text-shadow: 2px 4px 5px ${orange};
max-width: 1150px;
transition: .5s ease all;

`

// not used

export const HeroImage = styled(Img)`
height: 100%;
position: absolute;
left: 10rem;
width: 100vw;
// object-fit: cover;
top: 0rem;


`

// call to action statement

export const CtaStatementStyle = styled.h1`
font-size: 15rem;
// background: black;
font-family: 'Termina';

height: 100%;
z-index: 2;
color: white;
display: flex;
align-items: center;
padding-top: 2rem;
padding-left: .1em;
font-weight: 700;
text-transform: uppercase;
`

// article statement 
export const ArticleStatementStyle = styled.h1`
font-size: 6rem;
width: 75%;
font-family: 'Termina';
font-weight: 700;
padding-bottom: 2.5rem;
margin-left: 2rem;
padding-right: 5rem;
// background: black;
margin-bottom: 0;
max-width: 1150px;
height: 100%;
z-index: 2;
color: ${grey};
display: flex;
align-items: center;
padding-top: 10rem;
// padding-left: 1.5rem;
text-transform: uppercase;
// background: ${props => (props.pic ? `url(${props.pic})` : "none")}

${MEDIA.L_TABLET`

  `};

  ${MEDIA.TABLET`
  font-size: 5rem;
  padding-top: 7.5rem;
  // top: 7rem;
  `};

  ${MEDIA.PHONE`
  padding-top: 8.5rem;
  margin-left: 1rem;
     font-size: 3.33rem;
     width: 80%;
     line-height: 5rem;
     padding-bottom: 1.5rem;

  `};
  ${MEDIA.M_PHONE`
font-size: 2.5rem;

line-height: 4rem;
padding-top: 9.5rem;
padding-bottom: 2.5rem;
margin-bottom: -1rem;
  `};

  ${MEDIA.S_PHONE`
font-size: 2.5rem;
font-weight: 700;
line-height: 3.5rem;
padding-bottom: 0;
  `};
`

// category 


export const CategoryStatementStyle = styled.h1`
font-size: 8rem;
font-family: 'Termina';
letter-spacing: .75rem;
// background: black;
height: 100%;
z-index: 2;
color: grey;
display: flex;
align-items: center;
padding-top: 7.5rem;
padding-bottom: 5rem;
padding-left: .2em;
font-weight: 600;
text-transform: uppercase;


${MEDIA.L_TABLET`
  font-size: 6.5rem;
  margin-bottom: 0;
padding-top: 7rem;
padding-bottom: 0;
  `};

${MEDIA.TABLET`
  font-size: 5rem;
  // margin-bottom: 0;

  `};

  ${MEDIA.PHONE`
    position: relative;
    top: 0rem;
    letter-spacing: .25rem;
    padding-right: 4rem;
     font-size: 3rem;
     line-height: 7rem;
     padding-top: 33%;
      flex-direction: column;
  `};
  ${MEDIA.M_PHONE`
    
  `};
  ${MEDIA.S_PHONE`
    
  `};
`

export const CategoryStatementSubheader = styled.h2`
font-size: 4rem;
font-family: 'Termina';
position: relative;
max-width: 50rem;
// background: black;
height: 100%;
z-index: 2;
color: grey;
display: flex;
align-items: center;
padding-top: 2rem;
padding-left: 5rem;
font-weight: 600;
text-transform: uppercase;
letter-spacing: .3rem;
${MEDIA.L_TABLET`
  font-size: 4.5rem;
  margin-bottom: 0;
padding-top: 6rem;
padding-left: 4rem;
  `};

${MEDIA.TABLET`
  font-size: 3rem;
  // margin-bottom: 0;

  `};

  ${MEDIA.PHONE`
    top: 5rem;
    padding-left: 0rem;

     font-size: 2.5rem;
     line-height: 4rem;
     padding-top: 1rem;
    align-text: right;
  `};

  ${MEDIA.M_PHONE`
font-size: 2rem;
line-height: 2rem;

  `};
  
  ${MEDIA.S_PHONE`
    
  `};
`

export const TagStatementStyle = styled(CategoryStatementStyle)`
font-size: 6rem;
flex-direction: column;
align-items: flex-start;
justify-content: left;
padding-top: 10rem;
padding-bottom: 4rem;
margin-left: 2rem;
color: ${blue};

${MEDIA.TABLET`
  font-size: 4rem;
  // margin-bottom: 0;

  `};
`

export const TagStatementSubheader = styled(CategoryStatementSubheader)`
font-size: 3rem;
padding-top: 4rem;

${MEDIA.L_TABLET`
  margin-bottom: -10rem;
padding-top: 6rem;
padding-left: 4rem;
  `};

${MEDIA.TABLET`
  font-size: 3rem;
  // margin-bottom: 0;

  `};

  ${MEDIA.PHONE`
    top: 5rem;
    padding-left: 0rem;

     font-size: 2.5rem;
     line-height: 4rem;
     padding-top: 1rem;
    align-text: right;
  `};

${MEDIA.M_PHONE`
font-size: 1.75rem;
line-height: 2rem;
top: 2.5rem;
  `};
`