import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { blue, orange, grey, } from "../../constants/theme"

// index container
export const MainSectionContainer = styled.div`
min-height: 60rem;
width: 100%;
overscroll: hidden;
padding-top: 56.25%;
margin: 0 auto;
// background: rgba(0,0,0,0.05);
position: relative;
z-index: 2;


${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
top: .5rem;

  `};

  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`

  `};
`


export const SectionContainer = styled.div`
background: ${props => (props.bg ? props.bg :
    props.section === 'latest content' ? 'none'
      : 'none')};
position: relative;
z-index: 5;
width: 100%;
padding-bottom: ${props => (props.section === 'tags' ? '20rem' : '0')};
margin-bottom: -15vh;
height: ${props => (props.size === 'small' ? '30rem'
    : props.size === 'half' ? '55rem'
      : props.size === 'medium' ? '75rem'
        : props.size === 'large' ? '100rem'
          : 'max-content')};
font-size: 24px;
text-transform: uppercase;
overflow: hidden;
margin-top: ${props => (props.type === 'article' ? '-7.5rem' : '')};

@media screen and (min-height: 1150px) {
  padding-bottom: 7.5rem;
  
}


  ${MEDIA.L_TABLET`
margin-top: 12.5rem;

padding-bottom: ${props => (props.section === 'tags' ? '10rem' :
      props.type === 'cta' ? '10rem' : '0')};
  `};

  ${MEDIA.TABLET`
padding-bottom: ${props => (props.section === 'tags' ? '6rem' : '')};
margin-top: ${props => (props.type === 'article' ? '10rem' : '')};

  `};

  ${MEDIA.PHONE`
  padding-bottom: ${props => (props.section === 'tags' ? '0rem' :
      props.section === 'FOUR' ? '8rem' : '')};

  margin-top: ${props => (props.type === 'cta' ? '-10rem' :
      props.type === 'article' ? '9rem' :
        '')};
  
  height: ${props => (props.size === 'small' ? '25rem'
      : props.size === 'half' ? '40rem'
        : props.size === 'medium' ? '60rem'
          : props.size === 'large' ? '100rem'
            : 'auto')};
  `};

  ${MEDIA.M_PHONE`
  margin-top: ${props => (props.section === 'tags' ? '9rem' : '')};

  padding-bottom: ${props => (props.section === 'tags' ? '2.5rem' :
      props.section === 'FOUR' ? '8rem' : '')};

      padding-top: ${props => (props.section === 'tags' ? '0' :
      props.section === 'FOUR' ? '8rem' : '')};

  `};

  ${MEDIA.S_PHONE`
  padding-bottom: ${props => (props.section === 'tags' ? '0rem' : '')};
  height: ${props => (props.size === 'small' ? '25rem'
      : props.size === 'half' ? '30rem'
        : props.size === 'medium' ? '50rem'
          : props.size === 'large' ? '100rem'
            : 'auto')};
  `};

`;



export const CategorySection = styled.div`
min-height: 60rem;
width: 100%;
overscroll: hidden;
padding-top: 56.25%;
margin: 0 auto;
// top: 12.5rem;
// background: rgba(0,0,0,0.33);
background: rgba(0,0,0,0.05);
position: relative;
z-index: 2;

${MEDIA.TABLET`
// top: 1rem;

  `};
  ${MEDIA.PHONE`
top: .5rem;

  `};
`





export const CardList = styled.ul`

`

export const Tags = styled.div`
right: 0;
width: auto;
height: auto;
// background: pink;
top: 8rem;
position: absolute;
display: flex;
// align-items: flex-start;
// justify-content: center;
transition: .25s ease-in-out;
font-size: 8px;

// a:nth-child(1) {
//   margin-top: 4rem;
//   }
// a:nth-child(2) {
//     margin-top: 2rem;
//   }


a {
  transition: .25s ease-in-out;
  &:hover, &:active h1{
    cursor: pointer;
    background: rgba(2, 205, 252, 0.25);
    box-shadow: 2px 2px 10px 3px rgba(2, 205, 252, 0.5);
    border: 2.5px solid rgba(2, 205, 252, 0.25);
    border-radius: 5px;
  }
}
${MEDIA.L_TABLET`
display: none;


  `};


${MEDIA.PHONE`
top: 7.5rem;

  `};

${MEDIA.M_PHONE`

top: 6rem;
right: 0.66rem;
background: red;
font-size: 12px;
a {
  padding-right: .5px;
}
  `};
`

export const SideTab = styled.h1`
font-size: 1.33rem;
font-weight: 600;
color: ${blue};
margin-left: .5rem;
margin-right: .5rem;
z-index: 5;
// top: 17.5rem;
right: 1.5rem;
position: static;
writing-mode: vertical-rl;
text-orientation: mixed;
text-transform: uppercase;
letter-spacing: .2rem;
font-family: 'Termina';
&::before {
    content: ${props => (props.tag === undefined || props.tag === null ?
    `NTHEOS` : `"${props.tag}"`)};
    background: rgba(0, 0, 0, 0.075);
padding: .75px;
border: 2.5px  solid rgba(2, 205, 252, 0.5);



${MEDIA.L_TABLET`
right: 3rem;
  `};

${MEDIA.TABLET`
right: 1.75rem;
  `};


  ${MEDIA.PHONE`
right: 1.25rem;
font-size: 1rem;
top: 12.5rem;
  `};

  ${MEDIA.M_PHONE`
font-size: .6rem;
right: 0;
margin-left: 0;
margin-right: 0;
  `};

  ${MEDIA.S_PHONE`
right: .9rem;
top: 10rem;
font-size: .9rem;
  `};

`;

/// ARTICLE


export const ArticleSectionContainer = styled.div`
background: ${props => (props.bg === 'white' ? 'white' : 'none')};
position: relative;
z-index: 5;
width: 100%;
margin-bottom: -15vh;
height: ${props => (props.size === 'small' ? '25rem'
    : props.size === 'medium' ? '75rem'
      : props.size === 'large' ? '100rem'
        : 'auto')};
font-size: 24px;
text-transform: uppercase;
overflow: hidden;

${MEDIA.TABLET`
    
  `};
${MEDIA.PHONE`
  height: ${props => (props.size === 'small' ? '20rem'
      : props.size === 'medium' ? '80rem'
        : props.size === 'large' ? '200rem'
          : 'auto')};
  `};

`;

export const ArticleSection = styled.div`
min-height: 60rem;
width: 100%;
overscroll: hidden;
padding-top: 1.5rem;
margin: 0 auto;
// top: 12.5rem;
// background: rgba(0,0,0,0.33);
// background: rgba(0,0,0,0.025);
border-left: orange 3px solid;
position: relative;
z-index: 2;


${MEDIA.L_TABLET`
padding-top: 5rem;

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`

  `};

  ${MEDIA.M_PHONE`
  border-left: orange 1.5px solid;

  `};

  ${MEDIA.S_PHONE`

  `};

`

export const SideBlocker = styled.div`
background: rgba(0,0,0,0.1);
z-index: 5;
top: 17.5rem;
right: 3.5rem;
position: absolute;
visibility: ${props => props.type === "cta" ? "visible" : "hidden"};
height: 100%;
width: 3rem;


${MEDIA.L_TABLET`
right: 3rem;

  `}; 

${MEDIA.TABLET`
right: 1.75rem;

  `};


  ${MEDIA.PHONE`
right: 1.25rem;
font-size: 1.25rem;
top: 12.5rem;
  `};

  ${MEDIA.M_PHONE`
right: 1rem;

font-size: 1rem;
  `};

  ${MEDIA.S_PHONE`
right: .9rem;
top: 10rem;
font-size: .9rem;
  `};
`



export const Line = styled.hr`
// display: ${props => (props.section === "tags" ? `none` : "block")};
width: 100vw;
max-width: 1225px;
z-index: 5;
background: white;
border-width: 3px 0 3px 0;
border-color: ${blue};
// border-radius: 5px;
height: 1.25em;
position: absolute;
top: 3rem;
left: -0.75em;


${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
border-width: 3px 0 3px 0;
height: 1em;

  `};


  ${MEDIA.PHONE`
  border-width: 1.5px 0 1.75px 0;
  height: .75em;
  top: ${props => (props.type === "article" ? `1.5rem` : "")};
  `};

  ${MEDIA.M_PHONE`
  height: .7em;
  `};

  ${MEDIA.S_PHONE`

  `};

`


export const SectionTitle = styled.h1`
z-index: 6;
left: .5rem;
position: absolute;
text-shadow: 2px 2px ${blue};
top: 1rem;
background: ${props => (props.bg ? `"${props.bg}"` : "none")};
width: 100 %;
height: 3rem;
font-weight: 600;
letter-spacing: .25rem;
text-align: left;
font-size: 2.25rem;
color: ${orange};
font-family: "Termina";


&:: before {
  content: ${props => (`"${props.section}"`)}
  text-transform: uppercase;
}



${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
font-size: 1.5rem;
top: 1.25rem;
letter-spacing: .1rem;
text-shadow: 1.5px 1.5px ${blue};

  `};

  ${MEDIA.M_PHONE`
font-size: 1.15rem;
top: 1.75rem;
  `};

  ${MEDIA.S_PHONE`

  `};

`